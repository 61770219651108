import axios from 'axios';
import React, { useState } from 'react'
import { KdsLoginAPI } from '../../assets/api/BaseURL';
import { useNavigate } from 'react-router-dom';
import { useToast } from "../../../context/ToastContext";

export default function Login() {
    const navigate = useNavigate();
    const { addToast } = useToast();


    const [user, setUser] = useState({ username: "", password: "" });

    const handleInput = (event) => {
        setUser((prev) => ({ ...prev, [event.target.name]: event.target.value }));

    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = { username: user.username, password: user.password };

        try {
            const response = await axios.post(KdsLoginAPI, data);


            if (
                response.status === 200 &&
                response.data.token
            ) {
                addToast("success", response.data.message);

                localStorage.setItem("token", response.data.token);
                localStorage.setItem("staff_type", response.data.data.type);
                localStorage.setItem("staff_name", response.data.data.name);
                localStorage.setItem("staff_restaurant_name", response.data.data.restaurant_name);
                localStorage.setItem("user_role", response.data.data.userRole);

                localStorage.setItem("token", response.data.token);

                if (response.data.data.type === 'fooder')
                    navigate("/fooder");
                if (response.data.data.type === 'bar')
                    navigate("/bar");
                if (response.data.data.type === 'admin')
                    navigate("/admin");
            }

        } catch (error) {
            if (error.response && error.response.data.message) {

                setUser({ username: "", password: "" });
            } else {

                setUser({ username: "", password: "" });
            }
            console.log(error);
            addToast("error", error.response.data.message);
        }
    };
    return (
        <div>
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper px-0">
                    <div className="content-wrapper d-flex align-items-center auth px-0">
                        <div className="row w-100 mx-0">
                            <div className="text-center mb-2">
                                <img
                                    src="images/Khateraho_logo.png"
                                    alt="logo"
                                    className="loginlogo"
                                />
                                <p><b>Kitchen Display Systems</b></p>
                            </div>
                            <div className="col-lg-4 mx-auto">
                                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                    <h4>Hello! let's get started</h4>
                                    <h6 className="fw-light">Sign in to continue.</h6>
                                    <form className="pt-3">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                placeholder="Username"
                                                name="username"
                                                value={user.username}
                                                onChange={handleInput}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                className="form-control form-control-lg"
                                                placeholder="Password"
                                                name="password"
                                                value={user.password}
                                                onChange={handleInput}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <button
                                                className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn col-12"
                                                onClick={handleSubmit}
                                            >
                                                SIGN IN
                                            </button>
                                        </div>
                                        {/* <div className="my-2 d-flex justify-content-between align-items-center">
                                            <div className="form-check form-check-inline" />
                                            <a href="/forgot_password">
                                                <span className="auth-link text-black">Forgot password?</span>
                                            </a>
                                        </div>
                                        <div className="mb-2 text-center">
                                            <button
                                                type="button"
                                                className="btn btn-block btn-facebook auth-form-btn"
                                            >
                                                SIGN IN WITH OTP
                                            </button>
                                        </div>
                                        <div className="text-center mt-2 fw-light">
                                            Don't have an account?{" "}
                                            <a href="/signup">
                                                <span className="text-primary">Create</span>
                                            </a>
                                        </div> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
